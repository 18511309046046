
import React from "react"
import { graphql, Link } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="All posts" />
      <Bio />
      <p>
Are you curious about what <Link to={'/about/teens'}>teens</Link> have said about me?<br/>
Are you curious about what the <Link to={'/about/community'}>community</Link> have said about me?
Want to see past <Link to={'/about/appearances'}>appearances</Link>?

        <h2>My Biography:</h2>
Yehuda Fine, rabbi, teacher, family therapist and author, spent 16 years on the guidance staff at Yeshiva University and rescued scores of runaway teens from the streets of New York as documented in his previous book, Times Square Rabbi.<br/><br/>

Today he is known as America's most streetwise family and teen expert. He regularly conducts seminars for public schools, private and alternative schools, parent groups, social service agencies, youth groups, hospitals, recovery centers and social workers. He is a popular guest on talk radio and TV nationwide, and a frequent contributor to scores of magazines and newspapers.<br/><br/>

Currently, he resides in Florida. His dog Brooklyn is always by his side when he goes bass fishing. His citrus trees are his gardening joy. He never misses spring training.  <br/><br/>
Yehudah Fine is a unique spiritual teacher and therapist with a long history of leadership, courage, energy, and charisma necessary to move people and institutions.<br/>

<h2>Longer Biography</h2>
Fine is a nationally recognized figure in four separate communities: alternative education, recovery, Jewish education, and parenting. The concerns of these communities have moved from the background into the foreground of intense media attention in the last few years. Yehudah Fine is often chosen as the public spokesperson for these communities because of his ability to communicate their concerns to the general public. His work focuses on encouraging and assisting individuals and families to uncover solutions for their unresolved conflicts, pain, and self-destructive behavior.<br/><br/>

Fine began his career as a teacher/administrator with an M.Sc. in Biology and teaching credentials from the University of California at Davis in K-9 in single subject/multiple subjects, and in 9-12 single subject/multiple subject. He was the first person in the US to receive a Bilingual/Bicultural credential from the University of California at Davis. He used his training to create a school for migrant farm workers' children in the Davis public school system. He went on to create an award winning community school in the Mendocino school system also in California. Fine went on to gain administrative credentials as a Community School Director from the Mott Foundation, Detroit, MI. He could have easily continued a life-long career in alternative education, a movement that has gained considerable attention as our public school systems flounder. He has retained close ties to this community and is a regular keynote speaker at alternative education conferences.<br/><br/>

Moving beyond education into counseling, Fine studied for the rabbinate in Israel and Brooklyn. He completed a certification at the noted Ackerman Institute for the Family and assumed a position on the guidance faculty of Yeshiva University, which he maintains until the present. Fine's discovery of family systems theory and the role it plays within family systems with teen desperation, depression. and dysfunction, occurred approximately 15 years before its wide-spread consensus. He has always maintained that values and family dynamics play a critical role in thinking about the breakdown of families and teen problems.<br/><br/>

Fine became involved in the "recovery community" as he worked from 1985 to 1994 both on the streets, as well as in many other capacities, with homeless and runaway teens. He chronicled his experiences and approach to recovery in Times Square Rabbi: Finding Hope in Lost Kids' Lives (Hazelden 1997). His cutting-edge work in recovery resulted in an invitation to host a monthly recovery forum on AOL's Addiction and Recovery Network. Fine has continued his work with AOL as the service has grown, building a strong network and on-line presence in the recovery community. As with the family systems and teen work, recovery methodologies have achieved broader credibility as an approach for healing "regular" people.<br/><br/>

While traveling on his Times Square Rabbi book tour, Fine was galvanized by the loneliness and disconnectedness that had grown up among middle class teens while he was on the streets of NYC. Well before the media discovered teens, Fine worked to create a preventative parenting and teen program called Reclaiming Our Teenagers and Ourselves and has spent the last 3 years traveling around the US working with therapists, teachers, parents and teens to prevent teen suicide, violence and drug addiction. He has developed a nationwide training and public speaking circuit among local social service agencies, hospital adolescent health departments, teachers' conventions, and synagogue youth organizations. The programs have garnered rave reviews, strong media attention, and many return invitations to talk more broadly about family issues such as forgiveness.<br/><br/>

Forgiveness brings together the threads of healing families, individuals, spiritual transformation through recovery, and deep confrontation with classical religious sources. Fine's forgiveness forums offer a much needed common tent for dialogue about people's everyday problems with fellow workers, family members, deep family crisis, poverty, abuse, and self-destructive behavior.
</p>
    </Layout>
  )
}

export default About

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`